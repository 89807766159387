<template>
    <div>
        <vue-element-loading :active="appLoading" :is-full-screen="true" background-color="#FFFFFF" color="#68D389"
            spinner="bar-fade-scale" />
        <v-snackbar v-model="showSnackBar" color="#68D389" right :timeout="timeout">
            <v-layout wrap justify-center>
                <v-flex text-left align-self-center>{{ msg }}</v-flex>
                <v-flex text-right>
                    <v-btn small :ripple="false" text @click="showSnackBar = false">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-flex>
            </v-layout>
        </v-snackbar>
        <v-layout wrap justify-start>
            <v-flex xs6 text-left pb-5>
                <span class="itemHeading">Conservation Clock</span>
            </v-flex>
            <v-flex xs6 text-right>
                <v-dialog :retain-focus="false" v-model="dialog" persistent max-width="800px" :key="dialog">
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn outlined color="green" v-bind="attrs" v-on="on">
                            ADD
                        </v-btn>
                    </template>
                    <v-card>
                        <v-form>
                            <v-card-title>
                                <span class="headline">Add Details</span>
                            </v-card-title>
                            <v-card-text>
                                <v-container>
                                    <v-row>
                                        <v-col cols="12">
                                            <v-text-field outlined v-model="count" label="Count" required></v-text-field>
                                        </v-col>
                                        <v-col cols="12">
                                            <v-text-field outlined v-model="tittle" label="Tittle" required></v-text-field>
                                        </v-col>
                                        <v-col cols="12">
                                            <ImageComp @stepper="winStepper" :heading="'Upload Image'" :height="'2760'"
                                                :width="'5000'" :componentType="'conservationImage'" />
                                        </v-col>
                                    </v-row>
                                </v-container>
                            </v-card-text>
                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn color="blue darken-1" text @click="dialog = false">
                                    Close
                                </v-btn>
                                <v-btn color="blue darken-1" text @click="add()">
                                    Save
                                </v-btn>
                            </v-card-actions>
                        </v-form>
                    </v-card>
                </v-dialog>
            </v-flex>
            <v-flex xs12 sm6 md3 xl4 v-for="(item, i) in list" :key="i" pa-4>
                <v-card tile>
                    <v-layout wrap justify-center pb-2>
                        <v-flex xs12>
                            <v-img v-if="item.image" :src="mediaURL + item.image" height="200px">
                                <template v-slot:placeholder>
                                    <v-row class="fill-height ma-0" align="center" justify="center">
                                        <v-progress-circular indeterminate color=" #68D389"></v-progress-circular>
                                    </v-row>
                                </template>
                            </v-img>
                            <v-img v-else src="../../assets/images/no-image.png" height="200px" />
                        </v-flex>

                        <v-flex xs12 md12 lg12 xl12 :style="{ height: '0px' }" text-left pa-4 pt-2>
                            <span style="font-family: poppinsBold">{{
                                item.count
                            }}</span>
                        </v-flex>
                        <v-flex xs12 md12 lg12 xl12 :style="{ height: '0px' }" text-left pa-4 pt-2>
                            <span style="font-family: poppinsmedium">{{
                                item.tittle
                            }}</span>
                        </v-flex>
                        <v-flex xs12 md12 lg12 xl12 pt-16 pb-5>
                            <v-layout wrap justify-center pt-4>
                                <v-flex xs12 sm6 py-2 py-sm-0 px-2>
                                    <v-btn color="success" small block style="font-family: poppinsmedium" dark
                                        @click="editSlider(item)">Edit</v-btn>
                                </v-flex>
                                <v-flex xs12 sm6 py-2 py-sm-0 px-2>
                                    <v-dialog v-model="item.delete" max-width="600px">
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-btn small style="font-family: poppinsmedium" class="mr-2" dark block
                                                v-on="on" v-bind="attrs" color="red">
                                                Delete
                                            </v-btn>
                                        </template>
                                        <v-card>
                                            <v-card-title>Are you sure you want to delete this?</v-card-title>
                                            <v-card-actions>
                                                <v-spacer></v-spacer>
                                                <v-btn color="blue darken-1" text
                                                    @click="item.delete = false">Cancel</v-btn>
                                                <v-btn color="blue darken-1" text @click="deleteItem(item)">OK</v-btn>
                                                <v-spacer></v-spacer>
                                            </v-card-actions>
                                        </v-card>
                                    </v-dialog>
                                </v-flex>
                            </v-layout>
                        </v-flex>
                    </v-layout>
                </v-card>
            </v-flex>
            <v-dialog v-model="editdialog" max-width="800px">
                <v-card>
                    <v-card-title>
                        <span class="headline">Edit Solution</span>
                    </v-card-title>
                    <v-card-text>
                        <v-layout wrap justify-center>
                            <v-col cols="12">
                                <v-text-field outlined v-model="editingitem.count" label="Count" required></v-text-field>
                            </v-col>
                            <v-col cols="12">
                                <v-text-field outlined v-model="editingitem.tittle" label="Tittle" required></v-text-field>
                            </v-col>
                            <v-flex xs12 md12 lg12>
                                <ImageComp :singleImage="editingitem.image" @stepper="winStepper" :heading="'Upload Image'"
                                    :height="'2760'" :width="'5000'" :componentType="'conservationImage'" />
                            </v-flex>
                        </v-layout>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="blue darken-1" text @click="editdialog = false">
                            Close
                        </v-btn>
                        <v-btn color="blue darken-1" text @click="edit(editingitem._id)">
                            Save
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-layout>
        <v-layout v-if="list.length <= 0" wrap justify-center>
            <v-flex align-self-center pt-5>
                <span class="emptyField">No Data Available!!</span>
            </v-flex>
        </v-layout>
    </div>
</template>
<script>
import axios from "axios";
import ImageComp from "./singleImage";
export default {
    //   props: ["programs", "itemHeading"],
    components: {
        ImageComp
    },
    data() {
        return {
            appLoading: false,
            showSnackBar: false,
            dialog: false,
            timeout: 5000,
            list: [],
            msg: null,
            count: "",
            tittle: "",
            editdialog: false,
            editingitem: {},


        };
    },
    beforeMount() {
        this.getData();
    },
    methods: {
        winStepper(window_data) {
            if (window_data.type == "conservationImage") {
                this.conservationImage = window_data.selectedFiles;
            }
        },
        getData() {
            this.appLoading = true;
            axios({
                method: "GET",
                url: "/conservation/cycle/list",
            })
                .then((response) => {
                    this.appLoading = false;
                    this.list = response.data.data;
                })
                .catch((err) => {
                    this.appLoading = false;
                    this.ServerError = true;
                    console.log(err);
                });
        },
        add() {
            this.appLoading = true;
            var user = {};
            user["count"] = this.count;
            user["tittle"] = this.tittle;
            axios({
                url: "/add/conservation/cycle",
                method: "POST",
                data: user,
                headers: {
                    "x-auth-token": localStorage.getItem("token"),
                },
            })
                .then((response) => {
                    this.appLoading = false;
                    if (response.data.status) {
                        var id = response.data.id
                        if (this.conservationImage) {
                            this.uploadImage(id);
                        } else {
                            this.dialog = false
                            this.msg = "Added Sucessfully";
                            this.showSnackBar = true;
                            this.getData();
                        }
                        this.dialog = false

                    } else {
                        this.msg = response.data.msg;
                        this.showSnackBar = true;
                    }
                })
                .catch((err) => {
                    this.appLoading = false;
                    this.ServerError = true;
                    console.log(err);
                });
        },
        editSlider(item) {
            this.editingitem = item;
            this.editdialog = true;
        },
        edit() {
            this.appLoading = true;
            var user = {};
            user["count"] = this.editingitem.count;
            user["tittle"] = this.editingitem.tittle;
            user["id"] = this.editingitem;
            axios({
                url: "/edit/conservation/cycle",
                method: "POST",
                data: user,
                headers: {
                    "x-auth-token": localStorage.getItem("token"),
                },
            })
                .then((response) => {
                    this.appLoading = false;
                    if (response.data.status) {
                        this.editdialog = false
                        if (this.conservationImage) {
                            this.uploadImage(this.editingitem._id);
                        }
                        this.msg = "Edited Sucessfully";
                        this.showSnackBar = true;
                        this.getData();
                    } else {
                        this.msg = response.data.msg;
                        this.showSnackBar = true;
                    }
                })
                .catch((err) => {
                    this.appLoading = false;
                    this.ServerError = true;
                    console.log(err);
                });
        },
        uploadImage(id) {
            let formData = new FormData();
            formData.append("id", id);
            formData.append("image", this.conservationImage);

            axios({
                method: "POST",
                url: "/conservation/cycle/upload/image",
                data: formData,
                headers: {
                    "Content-Type": "multipart/form-data",
                    "x-auth-token": localStorage.getItem("token"),
                },
            })
                .then((response) => {
                    this.appLoading = false;
                    if (response.data.status == true) {
                        this.getData();
                        this.showSnackBar = true;
                        this.msg = "Uploaded Successfully";
                        this.dialog = false;
                    } else {
                        this.msg = "Can't Update";
                        this.showSnackBar = true;
                    }
                })
                .catch((err) => {
                    this.appLoading = false;
                    this.ServerError = true;
                    console.log(err);
                });
        },
        deleteItem(cat) {
            var data = {};
            data["id"] = cat._id;
            axios({
                url: "/delete/conservation/cycle",
                method: "POST",
                data: data,
                headers: {
                    "x-auth-token": localStorage.getItem("token"),
                },
            })
                .then((response) => {
                    this.delete = false;
                    this.appLoading = false;
                    if (response.data.status) {
                        this.msg = "Delete Sucessfully";
                        this.showSnackBar = true;
                        this.getData();
                    } else {
                        this.msg = response.data.msg;
                        this.showSnackBar = true;
                    }
                })
                .catch((err) => {
                    this.appLoading = false;
                    this.ServerError = true;
                    console.log(err);
                });
        },
    },
};
</script>