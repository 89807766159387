var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-snackbar',{attrs:{"color":"#68D389","right":"","timeout":_vm.timeout},model:{value:(_vm.showSnackBar),callback:function ($$v) {_vm.showSnackBar=$$v},expression:"showSnackBar"}},[_c('v-layout',{attrs:{"wrap":"","justify-center":""}},[_c('v-flex',{staticClass:"align-self-center",attrs:{"text-left":""}},[_c('span',{staticStyle:{"color":"#ffffff"}},[_vm._v(" "+_vm._s(_vm.msg)+" ")])]),_c('v-flex',{attrs:{"text-right":""}},[_c('v-btn',{attrs:{"small":"","ripple":false,"text":""},on:{"click":function($event){_vm.showSnackBar = false}}},[_c('v-icon',{staticStyle:{"color":"#ffffff"}},[_vm._v("mdi-close")])],1)],1)],1)],1),_c('v-layout',{attrs:{"wrap":""}},[_c('v-flex',{attrs:{"xs12":"","md12":"","align-self-center":""}},[_c('v-card',{staticClass:"text-capitalize",staticStyle:{"border":"1px solid #cccccc"},attrs:{"elevation":0,"outlined":"","ripple":false}},[_c('v-layout',{staticStyle:{"cursor":"pointer"},attrs:{"wrap":"","justify-center":"","py-2":""}},[_c('v-flex',{attrs:{"xs11":"","text-start":"","pl-4":""}},[_c('span',{staticClass:"textField1"},[_vm._v(" "+_vm._s(_vm.heading)+" ")]),_c('br')]),_c('v-flex',{attrs:{"xs1":"","align-self-center":"","text-right":""}},[(!_vm.singleImage && !_vm.resultImage)?_c('v-img',{staticStyle:{"cursor":"pointer"},attrs:{"height":"15px","src":require("./../../assets/iconsets/file-upload.svg"),"contain":""},on:{"click":function($event){return _vm.$refs.files.click()}}}):_vm._e()],1)],1),_c('v-dialog',{attrs:{"persistent":"","width":_vm.$vuetify.breakpoint.name == 'xs' ||
            _vm.$vuetify.breakpoint.name == 'sm'
            ? '100vw'
            : _vm.$vuetify.breakpoint.name == 'md'
              ? '80vw'
              : _vm.$vuetify.breakpoint.name == 'lg'
                ? '35vw'
                : '40vw'},model:{value:(_vm.isCropper),callback:function ($$v) {_vm.isCropper=$$v},expression:"isCropper"}},[_c('v-card',{staticClass:"pb-3"},[_c('v-card-title',{staticClass:"headline"},[_c('v-layout',{attrs:{"wrap":""}},[_c('v-flex',{attrs:{"md12":""}},[_c('span',[_vm._v(" Crop Image")])]),_c('v-flex',{attrs:{"text-right":""}},[_c('v-btn',{attrs:{"small":"","ripple":false,"text":""},on:{"click":function($event){_vm.isCropper = false}}},[_c('v-icon',{staticStyle:{"color":"#red"}},[_vm._v("mdi-close")])],1)],1)],1)],1),_c('Cropper',{ref:"cropper",staticClass:"example-cropper",attrs:{"stencil-props":{
              aspectRatio: _vm.width / _vm.height,
            },"src":_vm.image}}),_c('v-card-actions',[_c('v-layout',{attrs:{"wrap":"","justify-center":"","pt-3":""}},[_c('v-flex',{attrs:{"xs3":"","md3":""}},[_c('v-btn',{staticClass:"py-2 px-5",attrs:{"small":"","ripple":false,"color":"#68D389"},on:{"click":_vm.cropImage}},[_c('span',{staticStyle:{"color":"#ffffff","font-family":"poppinsregular"}},[_vm._v("Crop")])])],1),_c('v-flex',{attrs:{"md3":"","xs3":""}},[_c('v-btn',{staticClass:"py-2 px-3",attrs:{"small":"","ripple":false,"color":"#68D389"},on:{"click":function($event){return _vm.$refs.files.click()}}},[_c('span',{staticStyle:{"color":"#ffffff","font-family":"poppinsregular"}},[_vm._v("Change Image")])])],1)],1)],1)],1)],1),_c('v-layout',{attrs:{"wrap":"","justify-space-between":"","pa-4":""}},[_c('v-flex',{attrs:{"xs12":"","md9":"","pa-1":""}},[(_vm.singleImage && !_vm.resultImage)?_c('v-img',{attrs:{"src":_vm.mediaURL + _vm.singleImage}}):_c('v-img',{attrs:{"src":_vm.resultImage}})],1),(_vm.singleImage || _vm.resultImage)?_c('v-flex',{attrs:{"xs12":"","md3":"","align-self-center":""}},[_c('v-layout',{attrs:{"wrap":"","justify-center":""}},[_c('v-flex',{attrs:{"xs12":"","pa-2":""}},[_c('v-btn',{attrs:{"block":"","depressed":"","ripple":false},on:{"click":function($event){return _vm.$refs.files.click()}}},[_c('span',{staticStyle:{"font-size":"12px","text-align":"end","letter-spacing":"0px","color":"#000000","opacity":"1"}},[_vm._v(" UPLOAD NEW ")])])],1)],1)],1):_vm._e()],1)],1),_c('input',{directives:[{name:"show",rawName:"v-show",value:(false),expression:"false"}],ref:"files",attrs:{"accept":"image/*","id":"file1","type":"file"},on:{"change":_vm.browseImage}})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }